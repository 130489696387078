.formInput {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.formContractButton {
  text-align: right;
}

.panelButtons {
  margin-bottom: 16px;
  margin-top: 64px;
  padding-left: 4px;
  padding-right: 4px;
}
.header-person {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 35px;
}
.img__person {
  content: url(../assets/images/yacasiestas.png);
  height: auto;
  width: 250px;
}

.form__description {
  text-align: center;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.25px;
}