
.my-node-enter {
    opacity: 0;
}
.my-node-enter-active {
    opacity: 1;
    transition: opacity 500ms;
}
.my-node-exit {
    opacity: 1;
}
.my-node-exit-active {
    opacity: 0;
    transition: opacity 500ms;
}