.backIcon {
  color: #000 !important;
  font-size: 30px !important;
}

.barMenuContact {
  cursor: pointer;
  margin-right: 32px;
  text-align: right;
}

.barMenuTitle a {
  color: #ffffff;
  text-decoration: none;
}

.cardAppContainer {
  background-color: #ffffff;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 32px !important;
  overflow: hidden;
  padding: 16px;
}

.cardAppContainer.embed {
  max-width: 100%;
  flex: 100%;
}

.cardAppContainerFormBuilder {
  background-color: #ffffff;
  border-radius: 15px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 20% !important;
  padding: 16px;
  /* overflow-x: hidden; */

}

/* .cardAppContainer .MuiStepper-root {
  display: none;
} */

.header-sticker {
  padding: 52px 0px;
  background-color: #fff;
}

.header {
  color: #ffffff;
  padding: 0px 107px;
  /* margin: 16px 0px 0px;
  padding-left: 32px;
  padding-right: 8px; */
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.textTag {
  margin: 8px;
  width: 100%;
}

.mailLink a {
  color: darkcyan;
  font-weight: bold;
}

.MuiDrawer-paperAnchorRight {
  background-color: #141b6a !important;
}

.phoneLink a {
  color: darkcyan;
  font-weight: bold;
}

.wsLink a {
  color: springgreen;
  font-weight: bold;
}

@media (max-width: 980px) {
  .cardAppContainer {
    margin-bottom: 40px !important;
  }
}

@media (max-width: 600px) {
  .appContainer {
    padding-left: 8px;
    padding-right: 8px;
  }
  .header {
    padding: 0px 24px;
  }

  .cardAppContainerFormBuilder .react-grid-layout {
    height: auto !important;
  }
}

.card {
  background-color: #f0f2f5 !important;
  box-shadow: none !important;
}

.quote__description {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin: 0px 0px 5px 0px;
  width: 100%;
  color: #4b4b4b;
}
.quote__description span {
  font-weight: bold;
}

.quote__description--price {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin: 0px 0px 5px 0px;
  width: auto;
  color: #4b4b4b;
}
.quote__description--price span {
  font-weight: bold;
}
.quote__container--price {
  display: flex;
}

.arrowRight {
  position: relative;
  left: 5px;
}

/* Dots carousel noticias */
.BrainhubCarousel__dots {
  padding-top: 50px !important;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot {
  outline: 0;
  padding: 7px !important;
  border: none;
  opacity: 0.4 !important;
  font-size: 0;
  cursor: pointer;
  -webkit-appearance: none;
  background-color: grey !important;
  border-radius: 20px;
  margin: 0px 10px;
}

.BrainhubCarousel__dots .BrainhubCarousel__dot:before {
  background: #6716f5 !important;
  content: none !important;
}

.centered {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}
