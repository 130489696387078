.doneContact {
  margin: 32px;
}

.done__container--title {
  font-weight: normal;
  font-size: 34px;
  line-height: 36px;
  width: 100%;
  margin: 0px 0px 10px 0;
}
.done__container--subtitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  width: 100%;
  margin: 0px;
}
.done__container--description {
  font-size: 15px;
  line-height: 24px;
  width: 100%;
}

.header-done {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  width: 100%;
}
.img__done {
  content: url(../assets/images/felicitaciones.png);
  height: auto;
  width: 200px;
}