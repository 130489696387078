.cardButton {
  margin: 16px !important;
  padding: 6px 36px !important;
  background-color: '#141b6a';
}

.cardHome {
  margin: 16px;
  padding: 16px;
  text-align: justify
}

.cardGrid {
  padding: 16px;
}

.cardIcon {
  margin: 4px;
}

.cardText {
  margin: 8px;
}

.cardsContent {
  margin: 32px;
}

.shopContent {
  background: #F6F6F6;
  font-size: 30px;
  margin-top: 57px;
  text-align: end;
}

.shopIcon {
  margin: 4px;
}

.shopIconRight {
  margin-right: 64px;
}

.titleContent {
  flex-direction: column;
  margin-top: 60px;
  text-align: center;
}

@media (max-width: 960px) and (min-width: 600px) {
  .homeTitle {
    font-size: 3.5rem !important;
  }

  .homeSubTitle {
    font-size: 2.75rem !important;
  }
}

.titleContainer {
  width: 490px;
  justify-content: center;
  padding-top: 80px;
}

@media (max-width: 600px) {
  .homeTitle {
    font-size: 3rem !important;
  }

  .homeSubTitle {
    font-size: 2rem !important;
  }

  .titleContainer {
      width: auto;
  }
}

