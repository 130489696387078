@font-face {
  font-family: 'WorkSans';
  src: url("./assets/fonts/work_sans_ssv/WorkSans500/WorkSans-Regular.otf");
}

@font-face {
  font-family: 'Museo Sans';
  src: local('Museo Sans') url("./assets/fonts/museo_sans_ssv/MuseoSans500/MuseoSans_500.otf") format("opentype");
}

a {
  text-decoration: none;
}

body {
  background-color: #F4F4FA;
  font-family: 'Roboto', sans-serif;
}

.MuiGrid-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
}