.titleContainer {
  width: 490px;
  justify-content: center;
  padding-top: 80px;
  padding-left: 40px;
}

@media (max-width: 600px) {
  .titleContainer {
      width: auto;
      padding-right: 0;
      padding-left: 0;
  }
}

