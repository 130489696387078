.resumeCard {
  background-color: #F0F2F5 !important;
  box-shadow: none !important;
}

.resumeCardContent {
  text-align: center;
}

.resumeCardLine {
  text-decoration: line-through;
}

.resumeCoverIcon {
  font-size: 32px !important;
}

.resumePanel {
  box-shadow: none !important;
}

.resumePanelContainer {
  margin: 8px;
  margin-top: 32px;
}

.resumePanelButtons {
  margin-top: 64px;
}

.resumePanelContract {
  text-align: right;
}

.resumeTitle {
  margin: 16px;
  width: 100%;
}

.resumeTitleContainer {
  text-align: center;
}

.titlePanel {
  font-weight: bold !important;
}

.plan-container {
  border-radius: 20px;
  background-color: #4C1DB7;

  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #FFF;

  margin-top: 18.5px;
  height: 56px;

  display: flex;
  align-items: center;
  padding: 0px 21px;
}

.plan-coverages-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 7.5px;
}

.plan-coverages-container > div {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #E4DAF9;
  padding: 16px 0px 24px 0px;
}

.plan-coverages-container > div > div {
  flex: 1;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 0.15px;

  color: #4B4B4B;
}

.plan-coverages-container span {
  display: block;
  font-weight: 500;
  text-align: right;
}

.insured-container {
  margin-top: 38px;
}

.insured-container h4 {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  letter-spacing: 0.15px;

  color: #4C1DB7;
  margin-bottom: 5.5px
}

.validity-message-container {
  margin-top: 34px;
  background: #F4F4FA;
  border-radius: 15px;
  padding: 26px 24px;

  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* or 171% */

  letter-spacing: 0.15px;

  /* Primario 2 */

  color: #4C1DB7;
  text-align: center;
}

.options-container {
  margin-top: 35px;
}

.terms-container {
  margin: 44px 0px;
  padding: 0px 15px;
}

.date-container {
  margin-top: 26px;

  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.15px;

  color: #6716F5;
  text-align: center;
}

